.backgrounds { 
    .fit;
    .pos(a);
    top:0;
    left:0;
    overflow:hidden;
    .keyframes(bg;{
        0% {opacity:0;}    
        100% {opacity:1;}
    });

    @media @mobile {
        .bgc(#222);
    }
    
    @supports (animation-name: bg) {
        opacity:0;
    }

    .animation(bg 0.3s 0.4s forwards);
    @media @smart_landscape {
        height:120%;
    }

    img {
        .pos(a);
        left:0;
        top:0;
    }

}
.captcha-holder {
    border-bottom: 1px solid rgba(255,255,255,.5);
    padding: .625rem 0;
}


.home-bg, .contact-form {
    .fit;
    background-repeat:no-repeat;
    background-size:auto 100%;
    background-position:50% 50%;

    @media @tablet {
        background-size:auto 80%;
    }

    @media @min720 {
       background-size: cover; 
    }

    @media @smart_landscape {
        background-size:1024px auto; 
    }
}   

.contact-form {
    .pos(a,0,0);
}

.home-bg {
    .pos(a,0,0);
    overflow: hidden;

    @media @mobile {
        opacity:0.7;
    }
}

// Contenct 

.keyframes(container;{
    0% {opacity:0;}     
    100% {opacity:1;}
});

.container {
    .pos(a,0,0);
    .fit;
    z-index:5;
    @supports (animation-name: container) {
        opacity:0;
    }
    .animation(container 0.3s 0.2s forwards);
    @media @min1024 {
        height:~"calc(100% - 3vw)";
    }
    @media @smart_landscape {
        height:120%;
    }
}

.wrapper {
    margin:0 auto;
    .pos(r);

    @media @mobile {
        .get-rem(padding,0 25px);
        .bx;
    }

    @media @tablet {
        .get-rem(max-width,640px);
        .get-rem(padding,0 35px);
    }
   
    @media @min1024 {
        .get-rem(padding,0 100px);
        .get-rem(max-width,1024px);    
    }

    @media @min1280 {
       .get-rem(max-width,1280px);    
    }

    @media @min1440 {
       .get-rem(max-width,1366px);    
    }
}


.content {
    .pull-left;
    .pos(r);
    z-index:10;
    .get-rem(margin-top,100px);
    
    @media @tablet {        
        .get-rem(margin-top,150px);
    }
   
    @media @min1024 {
        .get-vw(margin-top,256px);        
    }
}

.holder {
    .pull-left;
    width:100%;
    .get-rem(margin-bottom,5px);
    @media @min1024 {
        .get-vw(margin-bottom,10px);
    }
}

// Home Titles 

.titles {
    .pull-left;
    width:100%;
    .pos(r);
    text-align:left;
    .get-vw(height,30px,320px);
    @media @tablet {
        .get-rem(height,70px);
    }

    @media @min1024 {
        .get-vw(height,166px);
    }
  
    @media @min1440 {
        .get-vw(height,150px);
    }
}

.home-title {
    font-family:@br;
    font-weight:900; 
    line-height:0.95;
    .upper;

    @media @mobile {
        .get-vw(font-size,45px,320px);
    }

    @media @tablet {
        .get-rem(font-size,100px);
    }
    @media @min1024 {
         .get-vw(font-size,220px);
    }
    @media @min1440 {
         .get-vw(font-size,204px);
    }

    &:not(.active) {
        .none;
    }
    
    &.active {
        z-index:1
    }
}


// Home Text 

.text-content {
    width:80%;
    .pos(r);
    z-index:2;
    font-family:@pd;
    .get-rem(font-size,20px);
    @media @mobile {
        .pull-left;
    }

    @media @min720 {
        .pull-right;
        width:50%;
    }
    
    @media @min1024 {
        .get-vw(font-size,33px);
    }
}

.home-text {
    @media @mobile {
        &:not(.active) {
            .none;
        }
    }
    @media @min720 {
        .pos(a,0,0);
    }
}



.button {
    display:block;
    .pos(r);
    font-family:@br;
    font-weight:900;
    line-height:1;
    z-index:15;
    .get-rem(font-size,18px);
    color:#fff;
    .upper;
    .get-rem(padding-bottom,5px);
    text-align:left;

    @media @mobile {
        padding:7px 0;
    }

    @media @tablet {
        width:20%;
        .get-rem(margin-left,10px);
    }

    @media @min1024 {
        .get-vw(padding-bottom,7px);
        .get-vw(margin,0 0 0 20px);        
    }

    @media @min1366 {
        .get-vw(font-size,21px);
    }

    &.active {
        .none;
    }

    &:after {
        position:absolute;
        bottom:0;
        left:0;
        content:'';
        width:100%;
        height:1px;
        .bgc(#fff);
        .get-rem(max-width,120px);
        display:inline-block;
        @media @min1024 {
            .get-vw(width,170px);
        }          
    }
}

.keyframes(line1_out;{
    0% {opacity:1;}
    50% {opacity:1;}
    100% {opacity:0;.none;}
});

.keyframes(line1;{
    0% {opacity:0;.block}
    50% {opacity:0;}
    100% {opacity:1;}
});


body:not(.opened-form) .contact-form {
    .none;  
}

@media @mobile_all {
    body.opened-form .total-wrapper {
        min-height:600px;
        position: relative;
    }
}

body.opened-form .contact-form {
    .animation(line1 0.3s  forwards);
}

// Contact Form 
.contact-form {
    .fit;
    z-index:10;
    .animation(line1_out 0.3s forwards);
     .bgimg("home/5b.jpg"); 

    @media @smart_landscape {
        height:120%;
    }

    .wrapper {
        height:100%;
    }
}


.contact-form-wrap {
    //width:50%;
    padding-right: 10%;
    box-sizing: border-box;
    // calcFooter
    height:80%;

    @media @mobile_all {
        margin:0 auto;
    }

    @media @mobile {
        width:100%;
    }

    @media @tablet {
        width:80%;
    }

    @media @min1024 {
        .pull-right;
        width:50%;
        height:90%;
    }
}

.contact-text {
    font-family:@pd;
    .get-rem(font-size,20px);

    @media @mobile {
        .get-rem(margin,100px 0 10px);
    }

    @media @tablet {
        .get-rem(margin,50px 0 15px);
    }

    @media @min720 {
        width:70%;
    }
    
    @media @min1024 {
        .get-vw(margin-bottom,30px);
        .get-vw(font-size,33px);
    }
}

.contact-form label {
     .pull-left;
  
    @media @min1024 {
        .get-vw(font-size,21px);
    }
} 

.contact-form input, .contact-form textarea {
    font-family:@pd;
    .get-rem(font-size,18px);
    .get-rem(padding,10px 0);
    .pull-left;
   background:transparent;
   width:100%;
   .pull-left;
   border:0;
   border-bottom:1px solid fade(#fff,50%);
   .trans(0.3s border @ease-in-out);
   color:#fff;  
   .placeholder(#fff);
   @media @min1024 {
        .get-vw(font-size,24px);
        .get-vw(padding,12px 0);
   }
   
   @media @min1440 {
        .get-vw(font-size,21px);
   }

   &:focus {
        border-bottom:1px solid fade(#fff,100%);      
        color:#fff;
   }

   &.error {
        color:#06273c;
        border-bottom:1px solid #06273c;   
        .placeholder(#06273c);   
   }
    
}
#captcha-holder.error{
     border-bottom:1px solid #06273c;   
}
@media @smart_landscape { 
    .contact-form input:not(.captcha-field) {
       width:50%;
    }
    .captcha-field, .contact-form textarea {
        width:100%;
    }
}

.contact-form textarea {
    resize:none;
    @media @min1024 {
        .get-vw(height,70px);
    }
}

.captcha-holder {
    .pull-left;
    width:100%;
    .pos(r);
}

.captcha {
    position:absolute;
    right:0;
    top:0;
    height:100%;
    cursor:pointer;
}

.send-button {
     font-family:@br;
     .get-rem(font-size,19px);
     color: #9da800;
     .upper;
     font-weight: 800;
     .bgc(#fff);
     .round(40px);
     padding: 5px 30px;
    
     &:hover {
         cursor: pointer;
         .bgc(#e2eae3);
         .trans(0.3s all @ease-in-out);
     }
     @media @min1024 {
         .get-vw(font-size,24px);
         .get-vw(padding,5px 30px);
     }
}

.success, .captcha-holder {
   @media @mobile_all {
        .get-rem(margin-bottom,15px);
    }
    @media @min1024 {
        .get-vw(margin-bottom,30px);
    }
}

.success {
    .none;
    .pull-left;
    .upper;
    width:100%;
    .font-size(12px);
    font-weight:600;
    //opacity:0;
}
:not(output):-moz-ui-invalid {
     box-shadow:none;
    -moz-box-shadow: none;
}
input:required {
    box-shadow:none;
    -moz-box-shadow: none;
}
input:invalid {
    box-shadow:none;
    -moz-box-shadow: none;
}
.contact-form input, .contact-form textarea{
    box-shadow:none;
    -moz-box-shadow: none;
}