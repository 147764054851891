* {
  -webkit-font-smoothing: antialiased;
}
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}
html {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
blockquote {
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 0;
}
a img {
  border: 0 none;
}
a {
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
}
.object {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform-style: flat;
  -ms-transform-style: flat;
  -o-transform-style: flat;
}
.clr {
  clear: both;
}
.ul-reset {
  margin: 0;
  padding: 0;
}
.ul-reset li {
  list-style-type: none;
}
.h-reset h1,
.h-reset h2,
.h-reset h3,
.h-reset h4,
.h-reset p {
  margin: 0;
}
.w-reset {
  max-width: 100%;
  width: 100%;
}
.h-reset {
  height: auto;
}
.block {
  display: block;
}
.inline_block {
  display: inline-block;
}
.none {
  display: none;
}
.ov-hidden {
  overflow: hidden;
}
.ov-visible {
  overflow: visible;
}
.ov-o-hidden {
  opacity: 0;
  visibility: hidden;
}
.ov-o-visible {
  opacity: 1;
  visibility: visible;
}
input,
select,
textarea {
  outline: none;
}
input,
textarea {
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
}
select {
  outline: none;
  border-radius: 0;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  /* 1 */
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
blockquote {
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
}
button:focus {
  outline: 0;
}
.center {
  text-align: center;
}
.upper {
  text-transform: uppercase;
}
.lower {
  text-transform: lowercase;
}
.case {
  text-transform: normal;
}
.nocase {
  text-transform: none;
}
.italic {
  font-style: italic;
}
.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.pull-none {
  float: none;
}
.prel {
  position: relative;
}
.pabs {
  position: absolute;
}
.pfix {
  position: fixed;
}
.valign {
  position: relative;
  display: table;
}
.valign.fit {
  width: 100%;
  height: 100%;
}
.valign .middle {
  display: table-cell;
  vertical-align: middle;
}
.bx {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.fit {
  width: 100%;
  height: 100%;
}
.grid .col-d-100 {
  width: 100%;
}
.grid .col-d-100.abs-square {
  position: relative;
  padding-bottom: 100%;
}
.grid .col-d-100.abs-square.half {
  padding-bottom: 50%;
}
.grid .col-d-50 {
  width: 50%;
}
.grid .col-d-50.abs-square {
  position: relative;
  padding-bottom: 50%;
}
.grid .col-d-50.abs-square.half {
  padding-bottom: 25%;
}
.grid .col-d-33 {
  width: 33.333333333333336%;
}
.grid .col-d-33.abs-square {
  position: relative;
  padding-bottom: 33.333333333333336%;
}
.grid .col-d-33.abs-square.half {
  padding-bottom: 16.666666666666668%;
}
.grid .col-d-25 {
  width: 25%;
}
.grid .col-d-25.abs-square {
  position: relative;
  padding-bottom: 25%;
}
.grid .col-d-25.abs-square.half {
  padding-bottom: 12.5%;
}
.grid .col-d-20 {
  width: 20%;
}
.grid .col-d-20.abs-square {
  position: relative;
  padding-bottom: 20%;
}
.grid .col-d-20.abs-square.half {
  padding-bottom: 10%;
}
.grid .col-d-16 {
  width: 16.666666666666668%;
}
.grid .col-d-16.abs-square {
  position: relative;
  padding-bottom: 16.666666666666668%;
}
.grid .col-d-16.abs-square.half {
  padding-bottom: 8.333333333333334%;
}
.grid .col-d-14 {
  width: 14.285714285714286%;
}
.grid .col-d-14.abs-square {
  position: relative;
  padding-bottom: 14.285714285714286%;
}
.grid .col-d-14.abs-square.half {
  padding-bottom: 7.142857142857143%;
}
.grid .col-d-12 {
  width: 12.5%;
}
.grid .col-d-12.abs-square {
  position: relative;
  padding-bottom: 12.5%;
}
.grid .col-d-12.abs-square.half {
  padding-bottom: 6.25%;
}
.grid .col-d-11 {
  width: 11.11111111111111%;
}
.grid .col-d-11.abs-square {
  position: relative;
  padding-bottom: 11.11111111111111%;
}
.grid .col-d-11.abs-square.half {
  padding-bottom: 5.555555555555555%;
}
.grid .col-d-10 {
  width: 10%;
}
.grid .col-d-10.abs-square {
  position: relative;
  padding-bottom: 10%;
}
.grid .col-d-10.abs-square.half {
  padding-bottom: 5%;
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .grid .col-t-100 {
    width: 100%;
  }
  .grid .col-t-100.abs-square {
    position: relative;
    padding-bottom: 100%;
  }
  .grid .col-t-100.abs-square.half {
    padding-bottom: 50%;
  }
  .grid .col-t-50 {
    width: 50%;
  }
  .grid .col-t-50.abs-square {
    position: relative;
    padding-bottom: 50%;
  }
  .grid .col-t-50.abs-square.half {
    padding-bottom: 25%;
  }
  .grid .col-t-33 {
    width: 33.333333333333336%;
  }
  .grid .col-t-33.abs-square {
    position: relative;
    padding-bottom: 33.333333333333336%;
  }
  .grid .col-t-33.abs-square.half {
    padding-bottom: 16.666666666666668%;
  }
  .grid .col-t-25 {
    width: 25%;
  }
  .grid .col-t-25.abs-square {
    position: relative;
    padding-bottom: 25%;
  }
  .grid .col-t-25.abs-square.half {
    padding-bottom: 12.5%;
  }
  .grid .col-t-20 {
    width: 20%;
  }
  .grid .col-t-20.abs-square {
    position: relative;
    padding-bottom: 20%;
  }
  .grid .col-t-20.abs-square.half {
    padding-bottom: 10%;
  }
  .grid .col-t-16 {
    width: 16.666666666666668%;
  }
  .grid .col-t-16.abs-square {
    position: relative;
    padding-bottom: 16.666666666666668%;
  }
  .grid .col-t-16.abs-square.half {
    padding-bottom: 8.333333333333334%;
  }
  .grid .col-t-14 {
    width: 14.285714285714286%;
  }
  .grid .col-t-14.abs-square {
    position: relative;
    padding-bottom: 14.285714285714286%;
  }
  .grid .col-t-14.abs-square.half {
    padding-bottom: 7.142857142857143%;
  }
  .grid .col-t-12 {
    width: 12.5%;
  }
  .grid .col-t-12.abs-square {
    position: relative;
    padding-bottom: 12.5%;
  }
  .grid .col-t-12.abs-square.half {
    padding-bottom: 6.25%;
  }
  .grid .col-t-11 {
    width: 11.11111111111111%;
  }
  .grid .col-t-11.abs-square {
    position: relative;
    padding-bottom: 11.11111111111111%;
  }
  .grid .col-t-11.abs-square.half {
    padding-bottom: 5.555555555555555%;
  }
  .grid .col-t-10 {
    width: 10%;
  }
  .grid .col-t-10.abs-square {
    position: relative;
    padding-bottom: 10%;
  }
  .grid .col-t-10.abs-square.half {
    padding-bottom: 5%;
  }
}
@media only screen and (max-width: 719px) {
  .grid .col-m-100 {
    width: 100%;
  }
  .grid .col-m-100.abs-square {
    position: relative;
    padding-bottom: 100%;
  }
  .grid .col-m-100.abs-square.half {
    padding-bottom: 50%;
  }
  .grid .col-m-50 {
    width: 50%;
  }
  .grid .col-m-50.abs-square {
    position: relative;
    padding-bottom: 50%;
  }
  .grid .col-m-50.abs-square.half {
    padding-bottom: 25%;
  }
  .grid .col-m-33 {
    width: 33.333333333333336%;
  }
  .grid .col-m-33.abs-square {
    position: relative;
    padding-bottom: 33.333333333333336%;
  }
  .grid .col-m-33.abs-square.half {
    padding-bottom: 16.666666666666668%;
  }
  .grid .col-m-25 {
    width: 25%;
  }
  .grid .col-m-25.abs-square {
    position: relative;
    padding-bottom: 25%;
  }
  .grid .col-m-25.abs-square.half {
    padding-bottom: 12.5%;
  }
  .grid .col-m-20 {
    width: 20%;
  }
  .grid .col-m-20.abs-square {
    position: relative;
    padding-bottom: 20%;
  }
  .grid .col-m-20.abs-square.half {
    padding-bottom: 10%;
  }
  .grid .col-m-16 {
    width: 16.666666666666668%;
  }
  .grid .col-m-16.abs-square {
    position: relative;
    padding-bottom: 16.666666666666668%;
  }
  .grid .col-m-16.abs-square.half {
    padding-bottom: 8.333333333333334%;
  }
  .grid .col-m-14 {
    width: 14.285714285714286%;
  }
  .grid .col-m-14.abs-square {
    position: relative;
    padding-bottom: 14.285714285714286%;
  }
  .grid .col-m-14.abs-square.half {
    padding-bottom: 7.142857142857143%;
  }
  .grid .col-m-12 {
    width: 12.5%;
  }
  .grid .col-m-12.abs-square {
    position: relative;
    padding-bottom: 12.5%;
  }
  .grid .col-m-12.abs-square.half {
    padding-bottom: 6.25%;
  }
  .grid .col-m-11 {
    width: 11.11111111111111%;
  }
  .grid .col-m-11.abs-square {
    position: relative;
    padding-bottom: 11.11111111111111%;
  }
  .grid .col-m-11.abs-square.half {
    padding-bottom: 5.555555555555555%;
  }
  .grid .col-m-10 {
    width: 10%;
  }
  .grid .col-m-10.abs-square {
    position: relative;
    padding-bottom: 10%;
  }
  .grid .col-m-10.abs-square.half {
    padding-bottom: 5%;
  }
}
.grid .offset-d-1 {
  padding: 5rem;
  box-sizing: border-box;
}
.grid .offset-d-2 {
  padding: 2.5rem;
  box-sizing: border-box;
}
.grid .offset-d-3 {
  padding: 1.6666666666666667rem;
  box-sizing: border-box;
}
.grid .offset-d-4 {
  padding: 1.25rem;
  box-sizing: border-box;
}
.grid .offset-d-5 {
  padding: 1rem;
  box-sizing: border-box;
}
.grid .row--left {
  width: 100%;
  float: left;
}
.grid .col {
  float: left;
  display: block;
}
header {
  width: 100%;
  z-index: 15;
  position: absolute;
  left: 0;
  top: 1.25rem;
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  header {
    top: 1.875rem;
  }
}
@media only screen and (min-width: 1025px) {
  header {
    top: 2.81vw;
  }
}
header,
footer {
  -webkit-animation: ui 0.3s 0.1s forwards;
  -moz-animation: ui 0.3s 0.1s forwards;
  animation: ui 0.3s 0.1s forwards;
}
@supports (animation-name: ui) {
  header,
  footer {
    opacity: 0;
  }
}
@-moz-keyframes ui {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes ui {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ui {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact-button {
  float: right;
  position: relative;
  width: 50px;
  height: 50px;
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .contact-button {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (min-width: 1025px) {
  .contact-button {
    width: 74px;
    height: 74px;
  }
}
.contact-button img {
  width: 100%;
}
.contact-button .icon {
  -webkit-transition: 0.3s background ease-in-out;
  -moz-transition: 0.3s background ease-in-out;
  transition: 0.3s background ease-in-out;
  width: 100%;
  height: 100%;
  display: block;
  background: transparent url(../images/home/icon.png) no-repeat center center;
  background-size: 100%;
}
body.opened-form .contact-button .icon {
  background: transparent url(../images/home/close.png) no-repeat center center;
  background-size: 100%;
}
body:not(.opened-form) .anim::before,
body:not(.opened-form) .anim::after {
  height: 130%;
  width: 130%;
  left: -15%;
  top: -15%;
  content: ' ';
  -webkit-animation: pulse 2s linear infinite;
  animation: pulse 2s linear infinite;
  border: #ffffff solid 9px;
  border-radius: 100%;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  opacity: .6;
  position: absolute;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  z-index: 1;
}
@keyframes pulse {
  to {
    opacity: 0;
    transform: scale(1);
  }
}
.logo {
  width: 150px;
  float: left;
  display: block;
  top: 10px;
  left: 0;
}
@media only screen and (min-width: 1025px) {
  .logo {
    width: 223px;
  }
}
.logo img {
  display: block;
  width: 100%;
  height: 100%;
}
* {
  -webkit-font-smoothing: antialiased;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 16px;
}
@media only screen and (min-width: 1025px) {
  body {
    overflow: hidden;
  }
}
h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: normal;
}
.total-wrapper {
  width: 100%;
  height: 100%;
  background-color: #2a519e;
}
@media only screen and (max-width: 719px) {
  .total-wrapper {
    height: 500px;
    position: relative;
  }
}
@media only screen and (min-width: 1025px) {
  .total-wrapper {
    height: 100%;
  }
}
@media (max-height: 480px) {
  .total-wrapper {
    height: 120%;
  }
}
.home-title:not(.active),
.home-text:not(.active),
.home-bg:not(.active) {
  opacity: 0;
}
.text,
.home-bg {
  position: absolute;
  top: 0;
  left: 0;
}
.backgrounds {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-animation: bg 0.3s 0.4s forwards;
  -moz-animation: bg 0.3s 0.4s forwards;
  animation: bg 0.3s 0.4s forwards;
}
@-moz-keyframes bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 719px) {
  .backgrounds {
    background-color: #222;
  }
}
@supports (animation-name: bg) {
  .backgrounds {
    opacity: 0;
  }
}
@media (max-height:480px) {
  .backgrounds {
    height: 120%;
  }
}
.backgrounds img {
  position: absolute;
  left: 0;
  top: 0;
}
.captcha-holder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: .625rem 0;
}
.home-bg,
.contact-form {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 50% 50%;
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .home-bg,
  .contact-form {
    background-size: auto 80%;
  }
}
@media only screen and (min-width: 720px) {
  .home-bg,
  .contact-form {
    background-size: cover;
  }
}
@media (max-height:480px) {
  .home-bg,
  .contact-form {
    background-size: 1024px auto;
  }
}
.contact-form {
  position: absolute;
  top: 0;
  left: 0;
}
.home-bg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
@media only screen and (max-width: 719px) {
  .home-bg {
    opacity: 0.7;
  }
}
@-moz-keyframes container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  -webkit-animation: container 0.3s 0.2s forwards;
  -moz-animation: container 0.3s 0.2s forwards;
  animation: container 0.3s 0.2s forwards;
}
@supports (animation-name: container) {
  .container {
    opacity: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .container {
    height: calc(100% - 3vw);
  }
}
@media (max-height:480px) {
  .container {
    height: 120%;
  }
}
.wrapper {
  margin: 0 auto;
  position: relative;
}
@media only screen and (max-width: 719px) {
  .wrapper {
    padding: 0rem 1.5625rem;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .wrapper {
    max-width: 40rem;
    padding: 0rem 2.1875rem;
  }
}
@media only screen and (min-width: 1025px) {
  .wrapper {
    padding: 0rem 6.25rem;
    max-width: 64rem;
  }
}
@media only screen and (min-width: 1281px) {
  .wrapper {
    max-width: 80rem;
  }
}
@media only screen and (min-width: 1441px) {
  .wrapper {
    max-width: 85.375rem;
  }
}
.content {
  float: left;
  position: relative;
  z-index: 10;
  margin-top: 6.25rem;
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .content {
    margin-top: 9.375rem;
  }
}
@media only screen and (min-width: 1025px) {
  .content {
    margin-top: 13.33vw;
  }
}
.holder {
  float: left;
  width: 100%;
  margin-bottom: 0.3125rem;
}
@media only screen and (min-width: 1025px) {
  .holder {
    margin-bottom: 0.52vw;
  }
}
.titles {
  float: left;
  width: 100%;
  position: relative;
  text-align: left;
  height: 9.38vw;
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .titles {
    height: 4.375rem;
  }
}
@media only screen and (min-width: 1025px) {
  .titles {
    height: 8.65vw;
  }
}
@media only screen and (min-width: 1441px) {
  .titles {
    height: 7.81vw;
  }
}
.home-title {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 900;
  line-height: 0.95;
  text-transform: uppercase;
}
@media only screen and (max-width: 719px) {
  .home-title {
    font-size: 14.06vw;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .home-title {
    font-size: 6.25rem;
  }
}
@media only screen and (min-width: 1025px) {
  .home-title {
    font-size: 11.46vw;
  }
}
@media only screen and (min-width: 1441px) {
  .home-title {
    font-size: 10.63vw;
  }
}
.home-title:not(.active) {
  display: none;
}
.home-title.active {
  z-index: 1;
}
.text-content {
  width: 80%;
  position: relative;
  z-index: 2;
  font-family: "Playfair Display", serif;
  font-size: 1.25rem;
}
@media only screen and (max-width: 719px) {
  .text-content {
    float: left;
  }
}
@media only screen and (min-width: 720px) {
  .text-content {
    float: right;
    width: 50%;
  }
}
@media only screen and (min-width: 1025px) {
  .text-content {
    font-size: 1.72vw;
  }
}
@media only screen and (max-width: 719px) {
  .home-text:not(.active) {
    display: none;
  }
}
@media only screen and (min-width: 720px) {
  .home-text {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.button {
  display: block;
  position: relative;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 900;
  line-height: 1;
  z-index: 15;
  font-size: 1.125rem;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 0.3125rem;
  text-align: left;
}
@media only screen and (max-width: 719px) {
  .button {
    padding: 7px 0;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .button {
    width: 20%;
    margin-left: 0.625rem;
  }
}
@media only screen and (min-width: 1025px) {
  .button {
    padding-bottom: 0.36vw;
    margin: 0vw 0vw 0vw 1.04vw;
  }
}
@media only screen and (min-width: 1367px) {
  .button {
    font-size: 1.09vw;
  }
}
.button.active {
  display: none;
}
.button:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #fff;
  max-width: 7.5rem;
  display: inline-block;
}
@media only screen and (min-width: 1025px) {
  .button:after {
    width: 8.85vw;
  }
}
@-moz-keyframes line1_out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes line1_out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes line1_out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-moz-keyframes line1 {
  0% {
    opacity: 0;
    display: block;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes line1 {
  0% {
    opacity: 0;
    display: block;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes line1 {
  0% {
    opacity: 0;
    display: block;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body:not(.opened-form) .contact-form {
  display: none;
}
@media only screen and (max-width: 1024px) {
  body.opened-form .total-wrapper {
    min-height: 600px;
    position: relative;
  }
}
body.opened-form .contact-form {
  -webkit-animation: line1 0.3s forwards;
  -moz-animation: line1 0.3s forwards;
  animation: line1 0.3s forwards;
}
.contact-form {
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-animation: line1_out 0.3s forwards;
  -moz-animation: line1_out 0.3s forwards;
  animation: line1_out 0.3s forwards;
  background-image: url(../images/home/5b.jpg);
}
@media (max-height:480px) {
  .contact-form {
    height: 120%;
  }
}
.contact-form .wrapper {
  height: 100%;
}
.contact-form-wrap {
  padding-right: 10%;
  box-sizing: border-box;
  height: 80%;
}
@media only screen and (max-width: 1024px) {
  .contact-form-wrap {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 719px) {
  .contact-form-wrap {
    width: 100%;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .contact-form-wrap {
    width: 80%;
  }
}
@media only screen and (min-width: 1025px) {
  .contact-form-wrap {
    float: right;
    width: 50%;
    height: 90%;
  }
}
.contact-text {
  font-family: "Playfair Display", serif;
  font-size: 1.25rem;
}
@media only screen and (max-width: 719px) {
  .contact-text {
    margin: 6.25rem 0rem 0.625rem;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .contact-text {
    margin: 3.125rem 0rem 0.9375rem;
  }
}
@media only screen and (min-width: 720px) {
  .contact-text {
    width: 70%;
  }
}
@media only screen and (min-width: 1025px) {
  .contact-text {
    margin-bottom: 1.56vw;
    font-size: 1.72vw;
  }
}
.contact-form label {
  float: left;
}
@media only screen and (min-width: 1025px) {
  .contact-form label {
    font-size: 1.09vw;
  }
}
.contact-form input,
.contact-form textarea {
  font-family: "Playfair Display", serif;
  font-size: 1.125rem;
  padding: 0.625rem 0rem;
  background: transparent;
  width: 100%;
  float: left;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.3s border ease-in-out;
  -moz-transition: 0.3s border ease-in-out;
  transition: 0.3s border ease-in-out;
  color: #fff;
}
.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
  color: #fff;
}
.contact-form input:-moz-placeholder,
.contact-form textarea:-moz-placeholder {
  color: #fff;
}
.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
  color: #fff;
}
@media only screen and (min-width: 1025px) {
  .contact-form input,
  .contact-form textarea {
    font-size: 1.25vw;
    padding: 0.63vw 0vw;
  }
}
@media only screen and (min-width: 1441px) {
  .contact-form input,
  .contact-form textarea {
    font-size: 1.09vw;
  }
}
.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 1px solid #ffffff;
  color: #fff;
}
.contact-form input.error,
.contact-form textarea.error {
  color: #06273c;
  border-bottom: 1px solid #06273c;
}
.contact-form input.error::-webkit-input-placeholder,
.contact-form textarea.error::-webkit-input-placeholder {
  color: #06273c;
}
.contact-form input.error:-moz-placeholder,
.contact-form textarea.error:-moz-placeholder {
  color: #06273c;
}
.contact-form input.error:-ms-input-placeholder,
.contact-form textarea.error:-ms-input-placeholder {
  color: #06273c;
}
#captcha-holder.error {
  border-bottom: 1px solid #06273c;
}
@media (max-height:480px) {
  .contact-form input:not(.captcha-field) {
    width: 50%;
  }
  .captcha-field,
  .contact-form textarea {
    width: 100%;
  }
}
.contact-form textarea {
  resize: none;
}
@media only screen and (min-width: 1025px) {
  .contact-form textarea {
    height: 3.65vw;
  }
}
.captcha-holder {
  float: left;
  width: 100%;
  position: relative;
}
.captcha {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
}
.send-button {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 1.1875rem;
  color: #9da800;
  text-transform: uppercase;
  font-weight: 800;
  background-color: #fff;
  border-radius: 40px;
  padding: 5px 30px;
}
.send-button:hover {
  cursor: pointer;
  background-color: #e2eae3;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
@media only screen and (min-width: 1025px) {
  .send-button {
    font-size: 1.25vw;
    padding: 0.26vw 1.56vw;
  }
}
@media only screen and (max-width: 1024px) {
  .success,
  .captcha-holder {
    margin-bottom: 0.9375rem;
  }
}
@media only screen and (min-width: 1025px) {
  .success,
  .captcha-holder {
    margin-bottom: 1.56vw;
  }
}
.success {
  display: none;
  float: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 600;
}
:not(output):-moz-ui-invalid {
  box-shadow: none;
  -moz-box-shadow: none;
}
input:required {
  box-shadow: none;
  -moz-box-shadow: none;
}
input:invalid {
  box-shadow: none;
  -moz-box-shadow: none;
}
.contact-form input,
.contact-form textarea {
  box-shadow: none;
  -moz-box-shadow: none;
}
footer {
  position: relative;
  background-color: #000;
  clear: both;
  z-index: 20;
  font-size: 0.75rem;
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
  padding: 1.25rem 0rem;
}
@media only screen and (max-width: 1024px) {
  footer {
    float: left;
  }
}
@media only screen and (max-width: 719px) {
  footer {
    margin-top: -55px;
  }
}
@media (max-height:480px) {
  footer {
    margin-top: 0px;
  }
}
footer a {
  color: #fff;
}
footer a:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 720px) {
  footer {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  footer {
    padding: 1.56vw 0vw;
  }
}
footer .col {
  float: left;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  footer .col {
    width: 100%;
  }
}
@media only screen and (max-width: 719px) {
  footer .col {
    margin-bottom: 1.25rem;
  }
  footer .col:last-child {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  footer .col {
    width: 33.33%;
  }
}
@media only screen and (min-width: 1025px) {
  footer .col.col-1 {
    width: 45%;
  }
  footer .col.col-2 {
    width: 20%;
  }
  footer .col.col-3 {
    width: 35%;
  }
}
@media only screen and (max-width: 1024px) {
  footer .icon {
    display: inline-block;
  }
}
@media only screen and (min-width: 1025px) {
  footer .icon {
    float: left;
    margin-right: 0.52vw;
  }
}
footer .info {
  float: left;
}
@media only screen and (max-width: 1024px) {
  footer .info {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .footer-wrapper {
    text-align: center;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .footer-wrapper {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}
