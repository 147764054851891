header {
    width:100%;
    z-index: 15;
    .pos(a);
    left:0;
    .get-rem(top,20px);
    @media @tablet {
        .get-rem(top,30px);
    }
    @media @min1024 {
        .get-vw(top,54px);
    }
}

header,footer {

    @supports (animation-name: ui) {
        opacity:0;    
    }
    
    .keyframes(ui;{
        0% {opacity:0;}    
        100% {opacity:1;}
    });
    .animation(ui 0.3s 0.1s forwards);
}

.contact-button {
    .pull-right;
    .pos(r);
    width: 50px;
    height: 50px;


    @media @tablet {
        width: 50px;
        height: 50px;
    }

    @media @min1024 {
        width: 74px;
        height: 74px;
    }

    img {
        width:100%;
    }
    .icon {
        .trans(0.3s background @ease-in-out);
        .fit;
        .block;
        .bgr('home/icon.png',center,center);
        background-size:100%;
    }
}


body.opened-form .contact-button .icon {
    .bgr('home/close.png',center,center);
    background-size: 100%;
}

body:not(.opened-form) {
    .anim::before,
    .anim::after {
            height:130%;
            width:130%;
            left:-15%;
            top:-15%;
            
            content: ' ';

            -webkit-animation: pulse 2s linear infinite;
            animation: pulse 2s linear infinite;
            border: rgb(255, 255, 255) solid 9px;

            .round(100%);
            .bx;
            
            opacity: .6;
            position: absolute;
            .scale(0.7);
            z-index: 1;        
        }

        @keyframes pulse {
            to {
                opacity: 0;
                transform: scale(1);
        }
    }
}


.logo {
    width: 150px;    
    .pull-left;
    display: block;
    top: 10px;
    left: 0;


    @media @min1024 {
        width: 223px;
    }

    img {
        .block;
        .fit;
    }
}
