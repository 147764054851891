* {
    -webkit-font-smoothing: antialiased;
}

html,body {
	.fit;
}

body {
     font-family:@br;
     font-size:16px;
     @media @min1024 {
          overflow:hidden;
     }
}

h1,h2,h3,h4 {
     margin:0;
     font-weight:normal;
}

.total-wrapper {
     width:100%;
     height:100%;
     .bgc(#2a519e);
     @media @mobile {
        height:500px;
        .pos(r);
     }
     @media @min1024 {
          height:100%;
     }
     @media (max-height:480px) {
        height:120%;
     }          
}

.home-title:not(.active),
.home-text:not(.active),
.home-bg:not(.active) {
     opacity:0;
}

 // .title,
 .text,
 .home-bg {
     position: absolute;
     top: 0;
     left: 0;
 }

