footer {
    .prel;
    .bgc(#000);
    .clr;
    z-index: 20;
    .font-size(12px);
    font-family:@br;
    .upper;
    .get-rem(padding,20px 0);
    @media @mobile_all {
        .pull-left;
    }
    @media @mobile {
        margin-top:-55px;
    }
    @media @smart_landscape {
        margin-top:0px;
    }

    a {
        color:#fff;
        &:hover {
            text-decoration:underline;
        }
    }
    @media @min720 {
        .bgc(fade(#000,60%));
        .pabs;
        bottom:0;
        width: 100%;
    }

    @media @min1024 {
        .get-vw(padding,30px 0);
    }

    .col {
        .pull-left;
        .bx;
        @media @mobile_all {
            width:100%;
        }

        @media @mobile {
            .get-rem(margin-bottom,20px);
            &:last-child {
                margin-bottom:0px;
            }
        }
        
        @media @tablet {
            width:33.33%;
        }

        @media @min1024 {
            &.col-1 {
                width:45%;
            }

            &.col-2 {
                width:20%;
            }

            &.col-3 {
                width:35%;
            }
        }
      

        
    }


    .icon {
        @media @mobile_all {
            display:inline-block;
        }
        @media @min1024 {
            .pull-left;
            .get-vw(margin-right,10px);
        }
    }

    .info {
        .pull-left;
        @media @mobile_all {
            width:100%;
        }
    }

}

.footer-wrapper {
    @media @mobile_all {
        text-align:center; 
    }
    @media @tablet {
        display: flex;
        -webkit-align-items: center;
        align-items: center;
    }
}