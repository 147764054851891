/////////////// Global ///////////////

* {
    -webkit-font-smoothing: antialiased;
}

html,body {
	width:100%;
	margin:0;
	padding:0;
}


html {
   -webkit-overflow-scrolling: touch;
   scroll-behavior: smooth;

   font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

}

body {
	font-size:16px;
	color:#fff;
	font-weight:400;
}

h1,h2,h3,h4,h5,h6 {
	margin:0;
}

blockquote {
	padding:0;
	margin:0;
}

button {
	cursor:pointer;
	border:0;
	background:transparent;
	padding:0;
}

button:focus {
	outline:0;
}


a img {
	border:0 none;
}

a {
	text-decoration: none;
	-webkit-text-decoration-skip: objects; 
}

.object {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;

	-moz-transform-style: flat;
	-ms-transform-style: flat;
	-o-transform-style: flat;
}

/////////////// Colors ///////////////

@black : #000; 
@white : #000;

/////////////// Media Queries ///////////////

@min720: ~"only screen and (min-width: 720px)";
@min1024: ~"only screen and (min-width: 1025px)";
@min1280: ~"only screen and (min-width: 1281px)";
@min1366: ~"only screen and (min-width: 1367px)";
@min1440: ~"only screen and (min-width: 1441px)";
//@min1660: ~"only screen and (min-width: 1661px)";

@tablet:   ~"only screen and (min-width: 720px) and (max-width: 1024px)";
@mobile:  ~"only screen and (max-width: 719px)";


@mobile_only: ~"only screen and (min-width: 480px) and (max-width: 719px)";
@mobile_all: ~"only screen and (max-width: 1024px)";
@minHeight: ~"only screen and (min-height: 800px)";
@smart_landscape: ~"(max-height:480px)";


/////////////// Resets ///////////////

.clr {clear:both;}

.ul-reset{
	margin:0;
	padding:0; 
	li {list-style-type:none}
}

.h-reset {
	h1,h2,h3,h4,p {
		margin:0;
	}
}

.w-reset {
	max-width:100%;
	width:100%;
}

.h-reset {
	height:auto;
}


/////////////// Block ///////////////

.block {display: block;}
.inline_block {display:inline-block;}

/////////////// Hidden ///////////////
.none {display:none;}
.ov-hidden {overflow: hidden;}
.ov-visible {overflow: visible;}

.ov-o-hidden {
	opacity:0;
	visibility:hidden;
}

.ov-o-visible {
	opacity:1;
	visibility:visible;
}



///////////////  Forms ///////////////

input,select,textarea {outline:none}
input, textarea {
	outline:none;
	-webkit-appearance:none;
	border-radius:0;
}

select {
	outline:none;
	border-radius:0;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

//
//  * Show the overflow in IE.
//  * 1. Show the overflow in Edge.
 

button,
input { 
  overflow: visible;
}

// *
//  * Remove the inheritance of text transform in Edge, Firefox, and IE.
//  * 1. Remove the inheritance of text transform in Firefox.
 

button,
select { /* 1 */
  text-transform: none;
}

// *
//  * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//  *    controls in Android 4.
//  * 2. Correct the inability to style clickable types in iOS and Safari.
 

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 2 */
}

// *
//  * Remove the inner border and padding in Firefox.
 

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

// *
//  * Restore the focus styles unset by the previous rule.
 

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}


blockquote {
	padding:0;
	margin:0;
}

button {
	cursor:pointer;
	border:0;
	background:transparent;
	padding:0;
}

button:focus {
	outline:0;
}


.placeholder(@color) {

    &::-webkit-input-placeholder {
        color:@color;
    }
    &:-moz-placeholder {
        color:@color;
    }
    &:-ms-input-placeholder {
        color:@color;
    }
}

/////////////// Text ///////////////

.center {text-align: center}

.upper {text-transform: uppercase;}
.lower {text-transform: lowercase;}
.case {text-transform: normal;}
.nocase {text-transform: none}

.italic {font-style:italic;}

.size(@size:12px) {font-size:@size;}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/////////////// Floats ///////////////

.pull-left {float:left}
.pull-right {float:right}
.pull-none {float:none}

/////////////// Positions ///////////////

.prel {
    position:relative;
}

.pabs {
	position:absolute;
}

.pfix {
	position:fixed;
}


.valign {
  position: relative;
  display: table;

  &.fit {
    width:100%;
    height:100%;
  }

  .middle {
    display: table-cell;
    vertical-align: middle;
  }
}

/////////////// Style ///////////////

.backface(@value:hidden) {
	-webkit-backface-visibility: @value;
	-moz-backface-visibility: @value;
	-ms-backface-visibility: @value;
	backface-visibility: @value;
}

.bx {
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

.round(@radius: 40px) {
	border-radius: @radius;
}


.gradient (@startColor: #eee, @endColor: white) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);	
}

/////////////// Backgrouds ///////////////

@__image_root__: "../images/";

.bgr(@path, @left:0, @top:0,@color:transparent,@repeat: no-repeat) {
	background:@color url(~'@{__image_root__}@{path}') @repeat @left @top;
}

.bgimg(@path) {
	background-image:url(~'@{__image_root__}@{path}');
}

.bgimg_multiple(@path1,@path2) {
	background-image:url(~'@{__image_root__}@{path1}'), url(~'@{__image_root__}@{path2}');
}

.bgc( @color : transparent) {
	background-color:@color;
}

.base64(@type,@url) {
	background:data-uri('@{type};base64', '@{url}');
}

.img-reponsive(@dir,@name) {

	@media @mobile_all {
		.bgr('@{dir}/@{name}_lower.jpg',center,center);
		background-size:cover;
	}

	@media @min1024 {
		.bgr('@{dir}/@{name}_medium.jpg',center,center);
		background-size:cover;
	}

	@media @min1440 {
		.bgr('@{dir}/@{name}.jpg',center,center);
		background-size:cover;
	}

}

/////////////// Dimension ///////////////

.fit {
	width:100%;
	height:100%;
}

/////////////// Transform ///////////////

.scale_rotate(@ratio,@deg) {
   -webkit-transform: scale(@ratio) rotate(@deg);
    -ms-transform: scale(@ratio) rotate(@deg);
    transform: scale(@ratio) rotate(@deg);
}

.scale_skew(@ratio,@deg) {
   -webkit-transform: scale(@ratio) skew(@deg);
  	-ms-transform: scale(@ratio) skew(@deg);
    transform: scale(@ratio) skew(@deg);
}

.rotate(@deg) {
	-webkit-transform:rotate(@deg);		
		-ms-transform:rotate(@deg);		
			transform:rotate(@deg);
}

.rotateZ(@deg) {
	-webkit-transform:rotate3d(1,0,0,@deg);	
		-ms-transform:rotate3d(1,0,0,@deg);		
			transform:rotate3d(1,0,0,@deg);	
}
.scale(@ratio) {
	-webkit-transform: scale(@ratio);
	transform: scale(@ratio);
}

.tr-origin(@x:50%, @y:0, @z:0) {
	-webkit-transform-origin:@x @y @z;
	transform-origin:@x @y @z;
}

.x(@offset:0px) {
	-webkit-transform : translateX(@offset);
	transform : translateX(@offset);
}

.y(@offset:0px) {
	-webkit-transform : translateY(@offset);
	transform : translateY(@offset);
}


.z(@radio:1000px,@offset:0px,@ie:0%) {
	-webkit-transform :perspective(@radio) translateZ(@offset);
	transform :perspective(@radio) translateZ(@offset);
}



.transform(@args...){
    .loop-args(@argCount) when (@argCount > 0) {
        .loop-args(@argCount - 1);
        @arg: extract(@args, @argCount);
        @property: extract(@arg,1);
        @param: extract(@arg,2);
        -webkit-transform+_: ~"@{property}(@{param})";
        transform+_: ~"@{property}(@{param})";
    }
    .loop-args(length(@args));    
}

//  .transform(rotate, 20deg; scale, 1.5; translateX, 10px);


/////////////// Controls ///////////////

.user-s-none(@value:none) {
  -webkit-user-select: @value; 
  -moz-user-select: @value;    
  -ms-user-select: @value;
  user-select: @value;  
}

.pointer-events(@value:none) {
  -webkit-pointer-events: @value; 
  -moz-pointer-events: @value;    
  -ms-pointer-events: @value;
  pointer-events: @value;  
}


//////////////////// Animation  //////////////////


.keyframes(@name; @arguments) {
  @-moz-keyframes @name { @arguments(); }
  @-webkit-keyframes @name { @arguments(); }
  @keyframes @name { @arguments(); }
}

.animation(@arguments) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}

// .keyframes(simple;{
// 	0% {  }
// 	100% { }
// });

// .animation(simple 0.2s forwards);


// UNITS GENERATOR 

// Px to Rem
@baseSize: 16;
@unit:rem;

// Return Multiple Values
.get-rem(@prop,@values){
    .loop-args(@count) when (@count > 0) {
        .loop-args(@count - 1);
        @param: unit(extract(@values,@count) / @baseSize, @unit);
        @{prop}+_: ~"@{param}";
    }
    .loop-args(length(@values));    
}

.font-size(@font-size: @baseSize) {
	@rem: (@font-size / @baseSize);
	font-size: unit(@rem,@unit); 
}


// Generate PX TO VW
@viewport_width:1920;
@viewport_width_mobile:1024;
@vw_unit : vw;

.get-vw(@prop,@values,@vieport:@viewport_width){
    .loop-args(@count) when (@count > 0) {
        .loop-args(@count - 1);
        @vw-context:(@vieport*.01) * 1px;
        @param: round(unit(extract(@values,@count) / @vw-context, @vw_unit), 2);
        @{prop}+_: ~"@{param}";
    }
    .loop-args(length(@values));    
}


// Generate PX TO VH
@viewport_height:768;
@vh_unit : vh;

.get-vh(@prop,@values){
    .loop-args(@count) when (@count > 0) {
        .loop-args(@count - 1);
        @vh-context:(@viewport_height*.01) * 1px;
        @param: round(unit(extract(@values,@count) / @vh-context, @vh_unit), 2);
        @{prop}+_: ~"@{param}";
    }
    .loop-args(length(@values));    
}


// Position 

// How to use :  
    // .pos(
    // r = relative : 
    //  a = absoblute 
    //  f = fixed  
    //  s = static
    //  top = N
    //  left = N
    //  right = 30px
    //  bottom = 30px
    // );

// Example :
    //  .pos(a,10px,10px); 

    // position: absolute;
    // top: 10px;
    // left: 10px;

.pos(@position: null, @top: null, @left: null, @right: null, @bottom: null) {
  .property(position, @position);
  .property(top, @top);
  .property(right, @right);
  .property(bottom, @bottom);
  .property(left, @left);

  .property(@property, @value) {

    ._getProp(position) when (@position = r) {
      position: relative;
    }

    ._getProp(position) when (@position = a) {
      position: absolute;
    }

    ._getProp(position) when (@position = f) {
      position: fixed;
    }

    ._getProp(position) when (@position = s) {
      position: static;
    }

    ._getProp(top) when not (@top = null) {
      top: @value;
    }

    ._getProp(right) when not (@right = null) {
      right: @value;
    }

    ._getProp(bottom) when not (@bottom = null) {
      bottom: @value;
    }

    ._getProp(left) when not (@left = null) {
      left: @value;
    }

    ._getProp(@property);    

  };

}